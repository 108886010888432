@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* Remove spinner arrow */
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@layer base {
    /* For IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* For Webkit-based browsers (Chrome, Safari and Opera) */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
}

@layer base {
    .background-animate {
        background-size: 400%;
        -webkit-animation: AnimationName 3s ease infinite;
        -moz-animation: AnimationName 3s ease infinite;
        animation: AnimationName 3s ease infinite;
    }

    @keyframes AnimationName {
        0%,
        100% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
    }
}